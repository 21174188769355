
import { useMutation } from "@apollo/client";
import React, { createContext, useContext, useEffect } from "react";
import { AuthRoutes } from "../modules/auth/routes.enum";
import { AuthService } from "../modules/auth/services/auth.service";
import { EnvironmentService } from "../services/envoriment.service";
import { clientAuth } from "../services/graphql.service";
import { LocalStorageService } from "../services/local-storage.service";
import { useMain } from "./main";

interface AuthContextData {
  login(values: any): Promise<void>;
  loadingLogin: boolean;
  signOut(): void;
  isAuthentication(): boolean;
  hasPermission(permission?: string[]): boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { errorMessage, setLoading } = useMain();
  const { sendLogin } = AuthService();
  const [sendLoginMutation, { loading: loadingLogin }] = useMutation(
    sendLogin(),
    {
      client: clientAuth,
    }
  );

  useEffect(() => {
    const token = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageToken
    );
    if (!token || token === "undefined") {
      signOut();
    }
  }, []);

  const successLogin = (): string => {
    const environment = EnvironmentService.isDev
      ? "dev"
      : EnvironmentService.isStg
      ? ""
      : "";

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams && searchParams.get("redirect")) {
      const redirect: string = searchParams.get("redirect") as string;
      const app: string = redirect.split("//")[1].split(".")[0];
      const environmentUrl: string = redirect
        .split("//")[1]
        .split(".")[0]
        .split("-")[1];

      if (app.split(":")[0] === "localhost") {
        return `http://${app}/auto-login`;
      }

      return !environment ? `https://portal.linkfyscale.net/auto-login` :
      `https://portal-${environmentUrl === "canary" ? "canary" : environment}.linkfyscale.net/auto-login`;
    }

    if (origin.includes("localhost")) {
      return `${origin}/auto-login`;
    } else {
      return !environment ? `https://portal.linkfyscale.net/auto-login` : `https://portal-${environment}.linkfyscale.net/auto-login`;
    }
  };

  async function login(values: any) {
    setLoading(true);
    const { userName, password } = values;
    sendLoginMutation({
      variables: {
        userName,
        password,
      },
    }).then((result) => {
      if (result.errors) {
        console.log({ errors: result.errors });
        errorMessage("Houve um erro ao realizar a sua autenticação");
        setLoading(false);
        return;
      }

      const { data, success } = result.data.login;
      if (!data || !success) {
        errorMessage("Houve um erro ao realizar a sua autenticação");
        setLoading(false);
        return;
      }

      const { accessToken, refreshToken, permissions } = data;
      LocalStorageService.setLocalStorage(
        LocalStorageService.localStorageToken,
        accessToken
      );
      LocalStorageService.setLocalStorage(
        LocalStorageService.localStorageRefreshToken,
        refreshToken
      );
      LocalStorageService.setLocalStorageJSON(
        LocalStorageService.localStoragePermissions,
        permissions
      );

      const redirect = successLogin();
      window.location.href = redirect;

      setLoading(false);
    });
  }

  function signOut() {
    LocalStorageService.removeAllLocalStorage();
    if (
      !window.location.pathname.includes(AuthRoutes.LOGIN) &&
      !window.location.pathname.includes(AuthRoutes.LOGOUT) &&
      !window.location.pathname.includes(AuthRoutes.FORGOT_PASSWORD) &&
      !window.location.pathname.includes(AuthRoutes.RESET_PASSWORD) &&
      !window.location.pathname.includes(AuthRoutes.REGISTER)
    ) {
      window.location.href = `/logout`;
    }
  }

  const isAuthentication = () => {
    const token = LocalStorageService.getLocalStorage(
      LocalStorageService.localStorageToken
    );
    return !!token;
  };

  const hasPermission = (permission?: string[]) => {
    const permissions = LocalStorageService.getLocalStorageJSON<string[]>(
      LocalStorageService.localStoragePermissions
    );
    return (
      ((permission &&
        (permissions?.findIndex((_) => permission.includes(_)) ?? -1) >= 0) ||
        permissions?.includes("admin-system")) ??
      false
    );
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        loadingLogin,
        signOut,
        isAuthentication,
        hasPermission,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };

